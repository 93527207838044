import './Footer.css'
import Github from '@iconscout/react-unicons/icons/uil-github'
import Linkedin from '@iconscout/react-unicons/icons/uil-linkedin'
import Twitter from '@iconscout/react-unicons/icons/uil-twitter'

import Wave from "../../img/wave.png"
const Footer = () => {
    return ( 
        <div className="footer">
            <img src={Wave} alt="" style={{width:'100%'}}/>
            <div className="f-content">
                 <span>Freecodingboy@gmail.com</span>
                 <div className="f-icons">
                    <Github color='white' size='3rem'/>
                    <Linkedin color='white' size='3rem'/>
                    <Twitter color='white' size='3rem'/>
                 </div>
            </div>
        </div>
     );
}
 
export default Footer;