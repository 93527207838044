import './Works.css'
import alat from "../../img/alat.png"
import Softkodes from "../../img/softkodes.png"
import Slydea from "../../img/slydea.png"
import Rapt from "../../img/RAPT.png";
import sceptralhub from "../../img/sceptralhub.png"
import { themeContext } from '../../Context' 
import { useContext } from "react";
import { motion } from 'framer-motion';
const Works = () => {
   const theme = useContext(themeContext);
   const darkMode = theme.state.darkMode;
    return ( 
        <div className="works">
              <div className="awesome">
                <span style={{color:darkMode?'white':''}}>Works for all these</span>
                <span>Brands and clients</span>
                <span>
                   I have served numerous clients over the years, bringing their thoughts <br/> and imagination
                   into life using the web tools to create awesome web <br/>designs  that gives there business a top class looks
                   on the web space, <br/> and have made their brand an a voice in their area of expertise.

                </span>
                <a href=""><button className='button s-button'>Hire Me</button></a>
                <div className="blur s-blur1" style={{background:"#ABF1FF94"}}></div>
            </div>

            <div className="w-right">
                 <motion.div 
                 initial={{rotate:45}}
                 whileInView={{rotate:0}}
                 viewport={{margin:'-40px'}}
                 transition={{duration:3.5, type:'spring'}}
                 className="w-mainCircle">
                 <div className="w-secCircle">
                    <img src={alat} alt="" style={{transform:'scale(0.3)'}}/>
                 </div>
                 <div className="w-secCircle">
                    <img src={Softkodes} alt="" style={{transform:'scale(0.4)'}}/>
                 </div>
                <div className="w-secCircle">
                    <img src={sceptralhub} alt="" style={{transform:'scale(0.2)'}}/>
                 </div> 
                 <div className="w-secCircle">
                    <img src={Rapt} alt="" style={{transform:'scale(0.4)'}}/>
                 </div>
                 <div className="w-secCircle">
                    <img src={Slydea} alt="" style={{transform:'scale(0.2)'}}/>
                 </div>
                 </motion.div>
                 <div className="w-backCircle blueCircle"></div>
                 <div className="w-backCircle yellowCircle"></div>
            </div>
        </div>
     );
}
 
export default Works;