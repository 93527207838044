import './Intro.css'
import Github from '../../img/github.png'
import Linkedln from '../../img/linkedin.png'
import Instagram from '../../img/instagram.png'
import Vector1 from '../../img/Vector1.png'
import Vector2 from '../../img/Vector2.png'
import boy from '../../img/boy.png'
import thumbup from '../../img/thumbup.png'
import Crown from '../../img/crown.png'
import glassesimoji from '../../img/glassesimoji.png'
import FloatingDiv from '../FloatingDiv/FloatingDiv'
import { themeContext } from '../../Context' 
import { useContext } from "react";
import {motion} from 'framer-motion'
const Intro = () => {

    const transition = {duration: 2, type: 'spring'}

    const theme = useContext(themeContext);
    const darkMode = theme.state.darkMode;
    return(
    <div className="intro">
        <div className="i-left">
            <div className="i-name">
                <span style={{color:darkMode? 'white':''}}>Hey! I Am</span>
                <span>Ebrusike Favour</span>
                <span>
                    I am an experienced software developer with 3 years of work experience who has strong passion for programming, learning and technology in general. I have worked on several enterprise web applications and have made progress due to innovative and creative approached used in handling software development based on each project requirement. I have worked with various software companies and financial firms; am very knowledgeable in using various software development methodology and architecture.
                </span>
            </div>
            <button className="button i-button">Hire me</button>
            <div className="i-icons">
                <a href="https://github.com/FavourWilson"><img src={Github} alt="" /></a>
                <a href="https://www.linkedin.com/in/favour-ebrusike-765463113/"><img src={Linkedln} alt="" /></a>
                <a href="https://www.instagram.com/freecodingboy/"> <img src={Instagram} alt="" /></a>
               
            </div>
        </div>
        <div className="i-right">
            <img src={Vector1} alt="" />
            <img src={Vector2} alt="" />
            <img src={boy} alt="" />

            <motion.img 
            initial={{left: '-36%'  }}
            whileInView={{left: '-24%'}}
            transition={transition}
            src={glassesimoji} alt="" />

            <motion.div 
            initial={{top:'-4%',left:'74%'}}
            whileInView={{left:'68%'}}
            transition={transition}
            style={{top:'-4%', left:'68%'}}
            className='floating-div'
            >
                <FloatingDiv image={Crown} txt1='Humble' txt2='Staff' />
            </motion.div>
            <motion.div
            initial={{left:'9rem', top:'18rem'}}
            whileInView={{left:'0rem'}}
            transition={transition}
            style={{top:'18rem', left:'0rem'}}
            className='floating-div'
            >
            <FloatingDiv image={thumbup} txt1='Best Design' txt2='Award' />
            </motion.div>

            <div className="blur" style={{background: "rgb(238 210 255)"}}></div>
            <div className="blur" style={{background: "#c1f5ff", 
            top: '17rem',  
            width:'21rem',
             height: '11rem', 
             left:'-9rem'}}></div>
        </div>
    </div>
    )
}

export default Intro;