import './Testimonials.css'
import {Swiper, SwiperSlide} from 'swiper/react'
import profilePic1 from "../../img/softkodes.png"
import profilePic2 from "../../img/slydea.png"
import profilePic3 from "../../img/RAPT.png";
import profilePic4 from "../../img/sceptralhub.png"
import { Pagination } from 'swiper'
import 'swiper/css/pagination'
import  'swiper/css'
const Testimonials = () => {
    const clients = [
        {
            img:profilePic1,
            review: "Favour is very good and reliable developer, always comes through when you need the best in the company",
        },
        {
            img:profilePic2,
            review: "We are glad to have him, his one of our very best",
        },
        {
            img:profilePic3,
            review: "You need a very good developer, then freecodingboy is your sure guy. His good and respectful",
        },
        {
            img:profilePic4,
            review: "You can count on him, anyday anytime, his always putting the company growth first",
        },
       
       
    ];
    return ( 
        <div className="testmonials" id='Testimonials'>
            <div className="t-wrapper">
                <div className="t-heading">
                    <span>Clients always get </span>
                    <span>Exceptional Work </span>
                    <span>from me....</span>
                    <div className="blur t-blurl1" style={{background: "var(--purple)"}}>

                    </div>
                    <div className="blur t-blurl2" style={{background: "skyblue"}}>

                    </div>
                </div>
                 <Swiper modules={[Pagination]} slidesPerView={1}
                 pagination={{clickable:true}}
                 >
                      {clients.map((client,index)=>{
                        return(
                            <SwiperSlide key={index}>
                                <div className="testimonial">
                                    <img src={client.img} alt="" style={{transform:'scale(0.5)'}} />
                                     <span>{client.review}</span>
                                </div>
                                
                            </SwiperSlide>
                        )
                      })}
                 </Swiper>
            </div>
        </div>
     );
}
 
export default Testimonials;