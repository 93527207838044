import React from 'react'
import './Navbar.css'
import { Link } from 'react-scroll';
import Toggle from '../Toggle/Toggle';
const Navbar = () => {
    return ( 
        <div className="n-wrapper">
            <div className="n-left">
                <div className="n-name">Ebrusike Favour</div>
                <span><Toggle/></span>
            </div>
            <div className="n-right">
                <div className="n-list">
                    <ul>
                        <Link spy={true} smooth={true} to='Navbar' activeClass='activeClass'>
                            <li>Home</li>
                        </Link>
                        
                        <Link spy={true} smooth={true} to='Services'>
                            <li>Services</li>
                        </Link>
                        <Link spy={true} smooth={true} to='Experience'>
                            <li>Experience</li>
                        </Link>
                        
                        <Link spy={true} smooth={true} to='Portfolio'>
                            <li>Portfolio</li>
                        </Link>
                        
                        <Link spy={true} smooth={true} to='Testimonials'>
                           <li>Testimonials</li>  
                        </Link>
                       
                    </ul>
                </div>
                <a style={{textDecoration:'none'}} className="button" href='+2349137571158'>Contact us</a>
            </div>
        </div>
     );
}
 
export default Navbar;