import './Experience.css'
const Experience = () => {
    return ( 
        <div className="experience" id='Experience'>
            <div className="achievement">
                <div className="circle" >3+</div>
                <span>years</span>
                <span>Experience</span>
            </div>
            <div className="achievement">
                <div className="circle">7+</div>
                <span>Tasks</span>
                <span>Projects</span>
            </div>
            <div className="achievement">
                <div className="circle">3+</div>
                <span>Companies</span>
                <span>Work</span>
            </div>
        </div>
     );
}
 
export default Experience;