import './Services.css'
import HeartEmoji from '../../img/heartemoji.png'
import Glasses from '../../img/glasses.png'
import Humble from '../../img/humble.png'
import Cards from '../Cards/Cards'
import Resume from './1-converted.pdf'
import { themeContext } from '../../Context' 
import { useContext } from "react";
import { motion } from 'framer-motion'
const Services = () => {
    const theme = useContext(themeContext);
    const darkMode = theme.state.darkMode;
    const transition = {duration: 2, type: 'spring'}

    return ( 
        <div className="services" id='Services'>
            <div className="awesome">
                <span style={{color:darkMode? 'white':'' }}>My Awesome</span>
                <span>Services</span>
                <span>
                    Providing top notch services for all clients,
                   
                     giving you the feeling of relaxation and quality. 
                     <br />
                     Web application is my major, but can get you top class personals on other services

                   
                </span>
                <a href=""><button className='button s-button'>Download Cv</button></a>
                <div className="blur s-blur1" style={{background:"#ABF1FF94"}}></div>
            </div>
            <div className="cards">
                <motion.div
                whileInView={{left: '14rem'}}
                initial={{left: '25%'}}
                transition={transition}
                style={{left: '14rem'}}>
                    <Cards emoji={HeartEmoji}
                    heading={'Design'}
                    detail = {"Figma, Sketch, Photoshop, Adobe, Adobe Xd"}
                    />
                </motion.div>
                <div style={{top: '12rem', left:'-4rem'}}>
                    <Cards emoji={Glasses}
                    heading={'Developer'}
                    detail = {"Html, css, Javascript, React, Angular,Laravel, C#"}
                    />
                </div>
                <div style={{top: '19rem', left:'12rem'}}>
                    <Cards emoji={Humble}
                    heading={'Data Science'}
                    detail = {"MATLAB,BigML,Excel,SAS,Apache Spark,D3,ggplot2,Tableau"}
                    />
                </div>
                <div className="blur s-blur2" style={{background:"var(--purple)"}}></div>
            </div>
        </div>
     );
}
 
export default Services;