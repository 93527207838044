 import './Portfolio.css'
import {Swiper, SwiperSlide} from 'swiper/react'
import work from "../../img/work-1.png"
import work2 from "../../img/work-2.png"
import work3 from "../../img/work-3.png"
import work4 from "../../img/work-4.png"
import 'swiper/css'
import { themeContext } from '../../Context' 
import { useContext } from "react";
 const Portfolio = () => {
    const theme = useContext(themeContext);
    const darkMode = theme.state.darkMode;
    return ( 
        <div className="portfolio" id='Portfolio'>
            <span style={{color:darkMode?'white':''}}>Recent Projects</span>
            <span>Portfolio</span>


            <Swiper
            spaceBetween={30}
            slidesPerView={3}
            grabCursor={true}
            className='portfolio-slider'
            >
                <SwiperSlide>
                   <a href="https://glsdesign.pages.dev/"><img src={work} alt=""/></a> 
                </SwiperSlide>
                <SwiperSlide>
                   <a href="https://admindashboard-78m.pages.dev/"><img src={work2} alt=""/></a> 
                </SwiperSlide>
                <SwiperSlide>
                <a href="https://fitclub-2qu.pages.dev/"><img src={work3} alt=""/></a>
                </SwiperSlide>
                <SwiperSlide>
                   <a href="https://www.alertegenerics.com/"><img src={work4} alt=""/></a> 
                </SwiperSlide>
            </Swiper>
        </div>
     );
 }
  
 export default Portfolio;